export default function externalComponentBeta(component) {
  // Production enviroment
  // const url = "https://api.library.welovecorner.com/" + component + "/index.umd.min.js";

  // Develop enviroment
  const url =
    process.env.VUE_APP_API_COMPONENTS + component + "/index.umd.min.js";

  let name = url
    .split("/")
    .reverse()[0]
    .match(/^(.*?)\.umd/)[1];

  window[name] = new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.async = true;
    script.addEventListener("load", () => {
      resolve(window[name]);
    });
    script.addEventListener("error", () => {
      reject(new Error(`Error loading ${url}`));
    });
    script.src = url;
    document.head.appendChild(script);
  });

  return window[name];
}
