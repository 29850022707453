import customAxios from '@/utils/customAxios.js';
import apiDomain from '@/config/api.js';
import externalComponentBeta from '@/utils/external-component-beta.js';
import VueUploadMultipleImage from 'vue-upload-multiple-image';

// Utils
import {addImage, deleteImage} from '@/utils/aws';

// Components
const FormInput = () => externalComponentBeta('formInput');

export default {
  data(){
    return{
      item: {
        title: '',
        cover: '',
        description: ''
      },
      imagesChanged: false,
      images: [],
      awsFolder: 'categories/'
    }
  },
  
  props: {
    id: String,
    action: String
  },

  components: {
    FormInput,
    VueUploadMultipleImage
  },

  methods: {
    getItem: async function() {
      let item = null;
      const $this = this;

      this.$parent.toggleLoader(true);

      await customAxios
        .get(apiDomain+'/panel/category/'+ this.id)
        .then(resp => item = resp)
        .catch(err => item = false);

      setTimeout(function(){
        $this.$parent.toggleLoader(false);
      }, 800);

      if(!item) {
        this.$toast.error('Error en la consulta');
      }else {
        if(item.status == 204){
          this.$toast.error('Error en la consulta');
        }else{
          this.item = item.data;

          if(item.data.cover) {
            this.images.push({
              default: 1,
              highlight: 1,
              name: "prev",
              path: item.data.cover
            });
          }
        }
      }
    },

    saveItem: async function() {
      let item = null;

      this.$parent.toggleLoader(true);

      if(this.imagesChanged) {
        await this.imagesAWS();
      }

      if(this.action == 'edit'){
        await customAxios
          .put(apiDomain+'/panel/category/'+ this.id, this.item)
          .then(resp => item = resp)
          .catch(err => item = false);
      }else{
        await customAxios
          .post(apiDomain+'/panel/category/', this.item)
          .then(resp => item = resp)
          .catch(err => item = false);
      }

      this.$parent.toggleLoader(false);

      if(!item) {
        this.$toast.error('Error en la consulta');
      }else {
        if(item.status == 204){
          this.$toast.error('Error en la consulta');
        }else{
          this.$toast.success('Guardado con éxito');
          this.$emit('toggle-modal', false);
        }
      }
    },

    saveChanges: function() {
      if(this.validateData()){
        this.saveItem();
      }
    },

    imagesAWS: async function() {
      if(this.item.cover){
        await deleteImage(this.item.cover);
      }

      this.item.cover = await addImage(this.images[0], this.item.title, this.awsFolder, 1920, true);
    },

    imagesUpdated: function(formData, index, fileList) {
      let $this = this;
      this.imagesChanged = true;

      setTimeout(function(){
        $this.images = fileList;
      }, 500)
    },

    imageDeleted: function(index, done, fileList) {
      let $this = this;
      this.imagesChanged = true;

      setTimeout(function(){
        $this.images.splice(index, 1);
      },500);
    },

    validateData: function() {
      let success = true;

      if(this.item.title == ''){
        success = false;
        this.$toast.error('Título inválido.');
      }else if(this.item.description == '') {
        success = false;
        this.$toast.error('Descripción inválida.');
      }else if(this.images.length == 0) {
        success = false;
        this.$toast.error('Selecciona una imagen.');
      }

      return success;
    }
  },

  mounted() {
    if(this.action == 'edit'){
      this.getItem();
    }
  }
}
