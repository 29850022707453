import customAxios from "@/utils/customAxios.js";
import apiDomain from "@/config/api.js";
import externalComponentBeta from "@/utils/external-component-beta.js";

// Components
const FormInput = () => externalComponentBeta("formInput");

export default {
  data() {
    return {
      item: {
        name: "",
        lastName: "",
        email: "",
        phone: "",
      },
      profiles: null,
      profilesDescription: null,
    };
  },

  props: {
    id: String,
    action: String,
  },

  components: {
    FormInput,
  },

  methods: {
    getItem: async function () {
      let item = null;
      const $this = this;

      this.$parent.toggleLoader(true);

      await customAxios
        .get(apiDomain + "/panel/creator/" + this.id)
        .then((resp) => (item = resp))
        .catch((err) => (item = false));

      setTimeout(function () {
        $this.$parent.toggleLoader(false);
      }, 800);

      if (!item) {
        this.$toast.error("Error en la consulta");
      } else {
        if (item.status == 204) {
          this.$toast.error("Error en la consulta");
        } else {
          this.item = item.data;

          if (
            this.item.hasOwnProperty("profiles") &&
            this.item.profiles.length
          ) {
            this.profiles = this.item.profiles
              .map((profile) => profile.title)
              .join(", ");

            const profilesDescription = this.item.profiles.filter(
              (profile) => profile.description != null
            );

            this.profilesDescription = profilesDescription
              .map((profile) => profile.description)
              .join(",");
          }
        }
      }
    },

    saveItem: async function () {
      let item = null;

      this.$parent.toggleLoader(true);

      if (this.action == "edit") {
        await customAxios
          .put(apiDomain + "/panel/creator/" + this.id, this.item)
          .then((resp) => (item = resp))
          .catch((err) => (item = false));
      } else {
        await customAxios
          .post(apiDomain + "/panel/creator/", this.item)
          .then((resp) => (item = resp))
          .catch((err) => (item = false));
      }

      this.$parent.toggleLoader(false);

      if (!item) {
        this.$toast.error("Error en la consulta");
      } else {
        if (item.status == 203) {
          this.$toast.error("El correo ya existe");
        } else {
          this.$toast.success("Guardado con éxito");
          this.$emit("toggle-modal", false);
        }
      }
    },

    saveChanges: function () {
      if (this.validateData()) {
        this.saveItem();
      }
    },

    validateData: function () {
      let success = true;
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.item.name == "") {
        success = false;
        this.$toast.error("Nombre no válido.");
      } else if (this.item.lastName == "") {
        success = false;
        this.$toast.error("Appellido no válido.");
      } else if (this.item.email == "" || !re.test(this.item.email)) {
        success = false;
        this.$toast.error("Correo no válido.");
      }

      return success;
    },
  },

  mounted() {
    if (this.action == "edit") {
      this.getItem();
    }
  },
};
