import { AWS, s3, bucketName } from "@/config/aws.js";
import { Buffer } from "buffer";

const awsConfig = {
  href: "https://my-scouter.s3.us-east-2.amazonaws.com/",
};

async function loadImage(url, elem) {
  return new Promise((resolve, reject) => {
    elem.onload = () => resolve(elem);
    elem.onerror = reject;
    elem.src = url;
  });
}

export const deleteImage = async function (imgUrl) {
  if (imgUrl) {
    let fileKey = imgUrl.replace(awsConfig.href, "");
    let fileKeyMobile = fileKey.split(".");
    fileKeyMobile = fileKeyMobile[0] + "-m." + fileKeyMobile[1];

    await s3.deleteObject({ Key: fileKey }, function (error, data) {
      if (error) {
        console.log(error.message);
      }
    });

    await s3.deleteObject({ Key: fileKeyMobile }, function (error, data) {
      if (error) {
        console.log(error.message);
      }
    });
  }
};

export const addImage = async function (
  file,
  name,
  folderKey,
  maxWidth = 1920,
  mobile = false
) {
  const rand = Math.floor(Math.random() * (999 - 100 + 1) + 100);
  const fileExt = "." + file.name.split(".").pop();
  const nameFile = slugify(name) + "-" + rand;
  const fileKey = folderKey + nameFile + fileExt;
  const fileKeyMobile = folderKey + nameFile + "-m" + fileExt;
  let response = null,
    error;

  const base64 = file.path.replace(/^data:image\/\w+;base64,/, "");
  let buf = Buffer.from(base64, "base64");

  let height, width;
  let canvas = document.createElement("canvas");
  let img = document.createElement("img");

  await loadImage(file.path, img);

  let ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);
  width = img.width;
  height = img.height;

  if (maxWidth && width > maxWidth) {
    buf = resizeImage(img, maxWidth, width, height);
  }

  if (mobile) {
    let bufMobile = resizeImage(img, 800, width, height);
    let uploadMobile = new AWS.S3.ManagedUpload({
      params: {
        Bucket: bucketName,
        Key: fileKeyMobile,
        Body: bufMobile,
        ContentEncoding: "base64",
        ACL: "public-read",
      },
    });

    await uploadMobile
      .promise()
      .then((data) => (response = data))
      .catch((err) => (error = err));
  }

  let upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: bucketName,
      Key: fileKey,
      Body: buf,
      ContentEncoding: "base64",
      ACL: "public-read",
    },
  });

  await upload
    .promise()
    .then((data) => (response = data))
    .catch((err) => (error = err));

  if (response) {
    return response.Location;
  } else {
    return error;
  }
};

const resizeImage = function (img, maxWidth, width, height) {
  let canvas = document.createElement("canvas");
  height *= maxWidth / width;
  width = maxWidth;

  canvas.width = width;
  canvas.height = height;
  let ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height);

  let dataUrl = canvas.toDataURL("image/jpeg");
  let buf = Buffer.from(
    dataUrl.replace(/^data:image\/\w+;base64,/, ""),
    "base64"
  );
  return buf;
};

const slugify = function (text) {
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  const to = "aaaaaeeeeeiiiiooooouuuunc------";

  const newText = text
    .split("")
    .map((letter, i) =>
      letter.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
    );

  return newText
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/&/g, "-y-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-"); // Replace multiple - with single -
};
