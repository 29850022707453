import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import Login from "./views/Login/Login.vue";
import Dashboard from "./views/Dashboard/Dashboard.vue";
import Interiors from "./views/Interiors/Interiors.vue";
import Exteriors from "./views/Exteriors/Exteriors.vue";
import Restrictions from "./views/Restrictions/Restrictions.vue";
import Categories from "./views/Categories/Categories.vue";
import UseCategories from "./views/UseCategories/UseCategories.vue";
import Properties from "./views/Properties/Properties.vue";
import Reservations from "./views/Reservations/Reservations.vue";
import Owners from "./views/Owners/Owners.vue";
import Creators from "./views/Creators/Creators.vue";
import CreatorProfiles from "./views/CreatorProfiles/CreatorProfiles.vue";
import Settings from "./views/Settings/Settings.vue";

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "login",
      component: Login,
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
    },
    {
      path: "/interiores",
      name: "interiors",
      component: Interiors,
    },
    {
      path: "/interiores/:id",
      name: "interior",
      component: Interiors,
    },
    {
      path: "/exteriores",
      name: "exteriors",
      component: Exteriors,
    },
    {
      path: "/exteriores/:id",
      name: "exterior",
      component: Exteriors,
    },
    {
      path: "/restricciones",
      name: "restrictions",
      component: Restrictions,
    },
    {
      path: "/restricciones/:id",
      name: "restriction",
      component: Restrictions,
    },
    {
      path: "/categorias",
      name: "categories",
      component: Categories,
    },
    {
      path: "/categorias/:id",
      name: "category",
      component: Categories,
    },
    {
      path: "/categorias-de-uso",
      name: "use categories",
      component: UseCategories,
    },
    {
      path: "/categorias-de-uso/:id",
      name: "use category",
      component: UseCategories,
    },
    {
      path: "/propiedades",
      name: "properties",
      component: Properties,
    },
    {
      path: "/propiedades/:id",
      name: "property",
      component: Properties,
    },
    {
      path: "/reservaciones",
      name: "reservations",
      component: Reservations,
    },
    {
      path: "/reservaciones/:id",
      name: "reservation",
      component: Reservations,
    },
    {
      path: "/propietarios",
      name: "owners",
      component: Owners,
    },
    {
      path: "/propietarios/:id",
      name: "owner",
      component: Owners,
    },
    {
      path: "/creadores",
      name: "creators",
      component: Creators,
    },
    {
      path: "/tipos-de-perfil",
      name: "creator-profiles",
      component: CreatorProfiles,
    },
    {
      path: "/tipos-de-perfil/:id",
      name: "creator-profiles",
      component: CreatorProfiles,
    },
    {
      path: "/creadores/:id",
      name: "creator",
      component: Creators,
    },
    {
      path: "/configuraciones",
      name: "settings",
      component: Settings,
    },
  ],
});
