import customAxios from '@/utils/customAxios.js';
import apiDomain from '@/config/api.js';
import externalComponentBeta from '@/utils/external-component-beta.js';

// Components
const Toolbar = () => externalComponentBeta('toolbar');
const Table = () => externalComponentBeta('table');
const Modal = () => externalComponentBeta('modal');
import ModalContent from '@/components/Restrictions/Modal/Modal.vue';

export default {
  name: 'restrictions',

  components: {
    Toolbar,
    Table,
    Modal,
    ModalContent
  },

  data() {
    return {
      toolbar: {
        title: 'Listado de Restricciones',
        mainAction: {
          name: 'Agregar Restricción',
          emit: 'add-item'
        }
      },
      columns: [
        {
          name: 'Nombre'
        },
        {
          name: 'Ícono'
        },
        {
          name: '',
          status: true
        }
      ],
      rows: [],
      modal: {
        'add': {
          title: 'Agregar Restricción',
          description: '',
          action: 'Agregar'
        },
        'edit': {
          title: 'Editar Restricción',
          description: '',
          action: 'Actualizar'
        }
      },
      modalVisible: false,
      modalAction: '',
      selectedId: null,
    }
  },

  methods: {
    getItems: async function() {
      let items = null;

      await customAxios
        .post(apiDomain + '/panel/restriction/filter')
        .then(resp => items = resp)
        .catch(err => items = false);

      if(!items) {
        this.$toast.error('Error en la consulta');
      }else if(items.status == 204) {
        this.rows = [];
      }else {
        this.rows = items.data.items;
      }
    },

    addItem: function(){
      this.modalAction = 'add';
      this.selectedId = null;
      this.modalVisible = true;
    },

    editItem: function(id) {
      this.modalAction = 'edit';
      this.selectedId = id;
      this.modalVisible = true;
    },

    singleDelete: function(id) {
      this.deleteConfirmation([id]);
    },

    deleteConfirmation: function(ids){
      let msg = "¿Estás seguro de eliminar ";
      msg += (ids.length > 1)?"estos elementos?":"este elemento?";

      this.$confirm({
        message: msg,
        button: {
          no: 'No',
          yes: 'Sí'
        },
        callback: confirm => {
          if(confirm) {
            this.deleteItems(ids);
          }
        }
      })
    },

    deleteItems: async function(ids){
      let response = null;

      this.toggleLoader(true);

      await customAxios
        .post(apiDomain + '/panel/restriction/delete', {items: ids})
        .then(resp => response = resp)
        .catch(err => response = false);

      this.toggleLoader(false);

      if(!response) {
        this.$toast.error('Error eliminando item');
      }else {
        if(response.status != 200){
          this.$toast.error('Error eliminando item');
        }else{
          this.$toast.success('Item/s eliminado/s con éxito');
          this.getItems();
        }
      }
    },

    toggleModal: function(status){
      this.modalVisible = status;

      if(!status){
        this.getItems();

        if(this.modalAction == 'edit'){
          this.$router.push({ path: '/restricciones'});
        }
      }
    },

    verifyUrlParams: function() {
      const id = this.$route.params.id || null;

      if(id) {
        this.selectedId = id;
        this.modalAction = 'edit';
        this.modalVisible = true;
      }
    }
  },

  watch: {
    $route() {
      this.verifyUrlParams();
    }
  },

  mounted(){
    this.verifyUrlParams();
    this.getItems();
  }
};
