import customAxios from '@/utils/customAxios.js';
import apiDomain from '@/config/api.js'
import externalComponentBeta from '@/utils/external-component-beta.js'

const Card = () => externalComponentBeta('card')
const CardIcon = () => externalComponentBeta('cardIcon')
const FormInput = () => externalComponentBeta('formInput');
const FormButton = () => externalComponentBeta('formButton');

export default {
  name: 'Dashboard',

  components: {
    Card,
    CardIcon,
    FormInput,
    FormButton
  },

  data() {
    return {
      visits: 0,
      fees: {
        scouter: 0,
        service: 0,
        iva: 0
      }
    }
  },

  methods: {
    getVisits: async function() {
      let response;
      const today = new Date();

      const day = today.getDate();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();

      await customAxios
        .get(apiDomain + '/panel/visit/'+ day + '/' + month + '/' + year)
        .then(res => response = res)
        .catch(err => console.log(err));

      if (typeof response !== 'undefined' && response.status === 200) {
        this.visits = response.data.amount;
      }
    },

    updateVisits: async function() {
      let self = this;
      let interval = 15 * 1000;

      await this.getVisits();

      setInterval(async function() {
        await self.getVisits();
      }, interval);
    },

    // Fees

    getFees: async function() {
      let fees = null, data = null;

      await customAxios
        .get(apiDomain + '/panel/fee')
        .then(resp => fees = resp)
        .catch(err => fees = false);

      if(fees) {
        data = fees.data;
        data.scouter = this.formatPercentage(data.scouter);
        data.service = this.formatPercentage(data.service);
        data.iva = this.formatPercentage(data.iva);
        this.fees = data;
      }
    },

    updateConfirmation: function(){
      this.$confirm({
        message: 'Todos los precios públicos de las locaciones serán actualizados.',
        button: {
          no: 'Cancelar',
          yes: 'Continuar'
        },
        callback: confirm => {
          if(confirm) {
            this.updateFees();
          }
        }
      })
    },

    updateFees: async function() {
      const {fees} = this;
      const {id} = fees;
      let response = null;

      if(this.validateFees()) {
        if(!id) {
          await customAxios
            .post(apiDomain + '/panel/fee/', fees)
            .then(resp => response = resp)
            .catch(err => response = false);
        }else {
          await customAxios
            .put(apiDomain + '/panel/fee/'+id, fees)
            .then(resp => response = resp)
            .catch(err => response = false);
        }        

        if(!response || response.status != 200) {
          this.$toast.error('Ocurrió un error actualizando');
        }else {
          this.$toast.success('Actualizado con éxito');
        }
      }
    },

    validateFees: function() {
      const {scouter, service, iva} = this.fees;
      let msg = "", success = true;

      if(!this.validatePercentage(scouter)) {
        msg = "Comisión de Scouter no válida";
        success = false;
      }else if(!this.validatePercentage(service)) {
        msg = "Tarifa de servicio no válida";
        success = false;
      }else if(!this.validatePercentage(iva)) {
        msg = "IVA no válido";
        success = false;
      }

      if(!success) {
        this.$toast.error(msg);
      }

      return success;
    },

    formatPercentage: function(val) {
      val *= 100;
      val = val.toFixed(1);

      return val;
    },

    validatePercentage: function(val) {
      let success = true;

      if(isNaN(val)) {
        success = false;
      }else if(val < 0 || val > 100) {
        success = false;
      }

      return success;
    },
  },

  mounted() {
    this.updateVisits();
    this.getFees();
  }
}
