import customAxios from "@/utils/customAxios.js";
import apiDomain from "@/config/api.js";
import externalComponentBeta from "@/utils/external-component-beta.js";
import { VueEditor } from "vue2-editor";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/es";

// Utils
import { addImage, deleteImage } from "@/utils/aws";

// Components
import VoerroTagsInput from "@voerro/vue-tagsinput";
const FormInput = () => externalComponentBeta("formInput");
const FormSelect = () => externalComponentBeta("formSelect");
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      map: {
        zoom: 8,
        location: {
          lat: 20.659698,
          lng: -103.349609,
        },
      },
      mapDefaultLocation: {
        lat: 20.659698,
        lng: -103.349609,
      },
      schedule: {
        mo: { from: null, to: null },
        tu: { from: null, to: null },
        we: { from: null, to: null },
        th: { from: null, to: null },
        fr: { from: null, to: null },
        sa: { from: null, to: null },
        su: { from: null, to: null },
      },
      timePickerOptions: {
        start: "06:00",
        step: "00:30",
        end: "23:30",
      },
      item: {
        title: "",
        categoryId: "",
        ownerId: null,
        gallery: [],
        location: null,
        state: null,
        description: "",
        allowedAreas: "",
        pricePerHour: "",
        minimumTime: "1",
        capacity: "",
        schedule: this.schedule,
        area: "",
        interior: [],
        exterior: [],
        restrictions: [],
        gps: [],
        tags: [],
        featured: false,
        status: "Aprobada",
        address: null,
        disabled: false,
        position: 1,
        prevPosition: null,
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["blockquote", "code-block"],
        ["link"],
        ["clean"],
      ],
      categories: [],
      useCategories: [],
      interiors: [],
      exteriors: [],
      restrictions: [],
      owners: [],
      images: [],
      imagesDeleted: [],
      awsFolder: "properties/",
      selectedCategory: {},
      selectedUseCategories: [],
      selectedOwner: {},
      selectedInteriors: [],
      selectedExteriors: [],
      selectedRestrictions: [],
      days: [
        { long: "Lunes", short: "mo" },
        { long: "Martes", short: "tu" },
        { long: "Miércoles", short: "we" },
        { long: "Jueves", short: "th" },
        { long: "Viernes", short: "fr" },
        { long: "Sábado", short: "sa" },
        { long: "Domingo", short: "su" },
      ],
      multiselect: {
        selectLabel: "Presiona enter para seleccionar",
        selectedLabel: "Seleccionado",
        deselectLabel: "Presiona enter para eliminar",
        placeholder: "Selecciona una opción",
      },
      tags: [],
      imagesChanged: false,
      awsFolder: "properties/",
      states: [],
      cities: [],
      locationOnEdit: null,
      featuredOptions: [
        {
          name: "Es propiedad destacada",
          value: true,
        },
        {
          name: "No es propiedad destacada",
          value: false,
        },
      ],
      positionOptions: this.getPositionOptions(),
      disabledOptions: [
        {
          name: "Es propiedad deshabilitada",
          value: true,
        },
        {
          name: "Es propiedad habilitada",
          value: false,
        },
      ],
    };
  },

  props: {
    id: String,
    action: String,
  },

  components: {
    VueUploadMultipleImage,
    Multiselect,
    FormInput,
    FormSelect,
    VueEditor,
    DatePicker,
    "tags-input": VoerroTagsInput,
  },

  methods: {
    geocodePosition: function (location) {
      let geocoder = new google.maps.Geocoder();
      let address = "";
      const $self = this;

      geocoder.geocode(
        {
          latLng: location.latLng,
        },
        function (responses) {
          if (responses && responses.length > 0) {
            address = $self.formatAddress(responses[0].formatted_address);
            $self.$refs.gmap.$el.value = address;
            $self.item.address = address;
          }
        }
      );
    },

    updateCoordinates: function (location) {
      let lat = location.latLng.lat();
      let lng = location.latLng.lng();

      this.geocodePosition(location);
      this.item.gps = [lat.toString(), lng.toString()];
    },

    setPlace: function (place) {
      this.currentPlace = place;
      let val = this.$refs.gmap.$el.value;

      if (place) {
        val = this.formatAddress(val);
        this.$refs.gmap.$el.value = val;
        this.item.address = val;

        this.map.location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        this.center = this.map.location;
        this.item.gps = [
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ];
      }
    },

    formatAddress: function (address) {
      let elements = address.split(",");
      let val = "";

      if (elements.length > 2) {
        val = "";

        for (let index = 0; index < elements.length - 2; index++) {
          val += elements[index] + ",";
        }

        val = val.slice(0, -1);
      }

      return val;
    },

    isValidPoint: function (point) {
      let response = true;

      if (typeof point === "undefined") {
        response = false;
      } else if (point.length !== 2) {
        response = false;
      } else if (isNaN(point[0]) || isNaN(point[1])) {
        response = false;
      }

      return response;
    },

    getItem: async function () {
      let item = null;
      const $this = this;

      this.$parent.toggleLoader(true);

      await customAxios
        .get(apiDomain + "/panel/property/" + this.id)
        .then((resp) => (item = resp))
        .catch((err) => (item = false));

      setTimeout(function () {
        $this.$parent.toggleLoader(false);
      }, 800);

      if (!item) {
        this.$toast.error("Error en la consulta");
      } else {
        if (item.status == 204) {
          this.$toast.error("Error en la consulta");
        } else {
          this.locationOnEdit = item.data.location;
          this.item = item.data;

          if (this.item.position) {
            this.item.prevPosition = this.item.position;
          }

          if (!this.item.schedule) {
            this.item.schedule = this.schedule;
          }

          if (this.item.address && this.item.address != "") {
            this.$refs.gmap.$el.value = this.item.address;
          }

          if (this.isValidPoint(this.item.gps)) {
            this.map.location = {
              lat: Number(this.item.gps[0]),
              lng: Number(this.item.gps[1]),
            };
          } else {
            this.map.location = this.mapDefaultLocation;
          }

          if (item.data.gallery && item.data.gallery.length) {
            item.data.gallery.forEach((element, key) => {
              this.images.push({
                default: key === 0 ? 1 : 0,
                highlight: key === 0 ? 1 : 0,
                name: "prev",
                path: element,
              });
            });
          }
        }
      }
    },

    getExtraData: async function () {
      await this.getCategories();
      await this.getUseCategories();
      await this.getInteriors();
      await this.getExteriors();
      await this.getRestrictions();
      await this.getOwners();

      if (this.action == "edit") {
        this.matchSelectedItems();
      }
    },

    getCategories: async function () {
      let items = null;

      await customAxios
        .get(apiDomain + "/panel/category")
        .then((resp) => (items = resp))
        .catch((err) => (items = false));

      if (items) {
        this.categories = items.data;
      }
    },

    getUseCategories: async function () {
      let items = null;

      await customAxios
        .get(apiDomain + "/panel/useCategory")
        .then((resp) => (items = resp))
        .catch((err) => (items = false));

      if (items) {
        this.useCategories = items.data;
      }
    },

    getInteriors: async function () {
      let items = null;

      await customAxios
        .get(apiDomain + "/panel/interior")
        .then((resp) => (items = resp))
        .catch((err) => (items = false));

      if (items) {
        this.interiors = items.data;
      }
    },

    getExteriors: async function () {
      let items = null;

      await customAxios
        .get(apiDomain + "/panel/exterior")
        .then((resp) => (items = resp))
        .catch((err) => (items = false));

      if (items) {
        this.exteriors = items.data;
      }
    },

    getRestrictions: async function () {
      let items = null;

      await customAxios
        .get(apiDomain + "/panel/restriction")
        .then((resp) => (items = resp))
        .catch((err) => (items = false));

      if (items) {
        this.restrictions = items.data;
      }
    },

    getOwners: async function () {
      let items = null;

      await customAxios
        .get(apiDomain + "/panel/creator")
        .then((resp) => (items = resp))
        .catch((err) => (items = false));

      if (items) {
        let arrayHelper = [];

        items.data.forEach((element) => {
          arrayHelper.push({
            _id: element._id,
            name: element.name + " " + element.lastName,
          });
        });

        this.owners = arrayHelper;
      }
    },

    matchSelectedItems: function () {
      const { interior, exterior, restrictions, useCategory } = this.item;
      const { category, owner, tags } = this.item;
      let arrayHelper = [];

      this.selectedCategory = category.length ? category[0] : {};
      this.selectedUseCategories = useCategory;
      this.selectedInteriors = interior;
      this.selectedExteriors = exterior;
      this.selectedRestrictions = restrictions;

      if (owner.length) {
        this.selectedOwner = {
          name: owner[0].name + " " + owner[0].lastName,
          _id: owner[0]._id,
        };
      }

      if (tags) {
        tags.forEach((element, key) => {
          arrayHelper.push({
            key: key,
            value: element,
          });
        });
      }

      this.tags = arrayHelper;
    },

    saveItem: async function () {
      let item = null;

      this.$parent.toggleLoader(true);

      if (this.imagesChanged) {
        await this.imagesAWS();
      }

      if (this.action == "edit") {
        await customAxios
          .put(apiDomain + "/panel/property/" + this.id, this.item)
          .then((resp) => (item = resp))
          .catch((err) => (item = false));
      } else {
        await customAxios
          .post(apiDomain + "/panel/property/", this.item)
          .then((resp) => (item = resp))
          .catch((err) => (item = false));
      }

      this.$parent.toggleLoader(false);

      if (!item) {
        this.$toast.error("Error en la consulta");
      } else {
        if (item.status == 204) {
          this.$toast.error("Error en la consulta");
        } else {
          this.$toast.success("Guardado con éxito");
          this.$emit("toggle-modal", false);
        }
      }
    },

    saveChanges: function () {
      if (this.validateData()) {
        this.getIdsFromSelectedItems();
        this.saveItem();
      }
    },

    getIdsFromSelectedItems: function () {
      this.item.useCategory = getIds(this.selectedUseCategories, "_id");
      this.item.interior = getIds(this.selectedInteriors, "_id");
      this.item.exterior = getIds(this.selectedExteriors, "_id");
      this.item.restrictions = getIds(this.selectedRestrictions, "_id");
      this.item.tags = getIds(this.tags, "value");
      this.item.categoryId = this.selectedCategory._id;

      if (this.selectedOwner) {
        this.item.ownerId = this.selectedOwner._id;
      } else {
        this.item.ownerId = null;
      }

      function getIds(selectedItems, key) {
        let arrayHelper = [];

        selectedItems.forEach((element) => {
          arrayHelper.push(element[key]);
        });

        return arrayHelper;
      }
    },

    imagesAWS: async function () {
      let arrayHelper = [];

      for (let index = 0; index < this.imagesDeleted.length; index++) {
        await deleteImage(this.imagesDeleted[index]);
      }

      for (let index = 0; index < this.images.length; index++) {
        const element = this.images[index];

        if (element.name == "prev") {
          arrayHelper.push(element.path);
        } else {
          let imageUrl = await addImage(
            element,
            this.item.title + index,
            this.awsFolder,
            1920,
            true
          );
          arrayHelper.push(imageUrl);
        }
      }

      this.item.gallery = arrayHelper;
    },

    imagesUpdated: function (formData, index, fileList) {
      let $this = this;
      this.imagesChanged = true;

      setTimeout(function () {
        $this.images = fileList;
      }, 500);
    },

    defaultImageUpdated: function (index, fileList) {
      let $this = this;
      this.imagesChanged = true;

      setTimeout(function () {
        $this.images = fileList;
      }, 500);
    },

    imageDeleted: function (index, done, fileList) {
      let $this = this;
      this.imagesChanged = true;

      setTimeout(function () {
        $this.imagesDeleted.push($this.images[index].path);
        $this.images.splice(index, 1);
      }, 500);
    },

    validateData: function () {
      let success = true;
      const item = this.item;

      if (item.title == "") {
        success = false;
        this.$toast.error("Título inválido.");
      } else if (!this.selectedCategory) {
        success = false;
        this.$toast.error("Categoría inválida.");
      } else if (item.location == null) {
        success = false;
        this.$toast.error("Municipio inválido.");
      } else if (item.state == null) {
        success = false;
        this.$toast.error("Estado inválido.");
      } else if (item.address == null) {
        success = false;
        this.$toast.error("Dirección inválida.");
      } else if (item.pricePerHour == "") {
        success = false;
        this.$toast.error("Precio por hora inválido.");
      }

      return success;
    },

    getStates: async function () {
      let states = [];

      await customAxios
        .get(apiDomain + "/public/states-and-cities")
        .then((resp) => {
          states = resp.data;
        })
        .catch((err) => {});

      return states;
    },

    getCities: async function (state) {
      let cities = [];

      await customAxios
        .get(apiDomain + "/public/states-and-cities?state=" + state)
        .then((resp) => {
          cities = resp.data;
        })
        .catch((err) => {});

      return cities;
    },

    getPositionOptions: function () {
      let helper = [];
      const limit = 15;

      for (let index = 1; index <= limit; index++) {
        helper.push({
          name: index,
          value: index,
        });
      }

      return helper;
    },
  },

  watch: {
    "item.state": async function (value) {
      if (value) {
        this.item.location = null;
        this.cities = await this.getCities(value);

        if (this.action == "edit" && this.locationOnEdit) {
          const findLocationOnCities = this.cities.find(
            (city) => city == this.locationOnEdit
          );

          if (findLocationOnCities != undefined) {
            this.item.location = this.locationOnEdit;
            this.locationOnEdit = null;
          }
        }
      }
    },
  },

  async beforeMount() {
    this.states = await this.getStates();
  },

  async mounted() {
    this.item.schedule = this.schedule;

    if (this.action == "edit") {
      await this.getItem();
    }

    this.getExtraData();
  },
};
