import Vue from 'vue';
import VueToast from 'vue-toast-notification'
import VueSession from 'vue-session'
import ToggleButton from 'vue-js-toggle-button'
import VueConfirmDialog from 'vue-confirm-dialog'
import InlineSvg from 'vue-inline-svg'

import * as VueGoogleMaps from 'vue2-google-maps'
import '@fortawesome/fontawesome-free/js/all.js';

import { Utils } from './mixins/utils.js';
import { TweenMax } from 'gsap';

import router from './router';
import 'vue-toast-notification/dist/theme-sugar.css'
import 'vue-progress-path/dist/vue-progress-path.css'

import App from './views/App/App.vue';
import './assets/static';


Vue.use(VueToast, {
  queue: true,
  position: 'top',
  duration: 3000,
  dismissible: true
})

Vue.use(VueSession, {persist: true});

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.component('inline-svg', InlineSvg)

Vue.use(ToggleButton);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbOjQdh0XAb6Jzn7-rUeZGyYbQ4jC5d_I',
    libraries: 'places,drawing,visualization',
  }
});

Vue.use(require('vue-moment'));
Vue.mixin(Utils);

Vue.config.productionTip = true;

new Vue({
  router,
  render: function (h) {
    return h(App);
  }
}).$mount('#app');
