import axios from "axios";
import { startAuthorization, axiosInterceptor } from "@/utils/customAxios.js";
import api from "@/config/api.js";
import externalComponentBeta from "@/utils/external-component-beta.js";

import Logo from "@/components/icons/Logo/Logo.vue";
import FirmaWlc from "@/components/icons/FirmaWlc";
import Contact from "@/components/icons/Contact";

// Components
const FormInput = () => externalComponentBeta("formInput");
const FormButton = () => externalComponentBeta("formButton");

export default {
  name: "Login",

  components: {
    Logo,
    FirmaWlc,
    Contact,
    FormInput,
    FormButton,
  },

  data() {
    return {
      title: "Scouter",
      user: {
        email: "",
        password: "",
      },

      error: {
        email: false,
        password: false,
      },
    };
  },

  methods: {
    validateFields: function () {
      let success = true;

      if (this.user.email === "" || this.user.password === "") {
        this.$toast.warning("Hay campo(s) vacío(s)");
        success = false;
      }

      return success;
    },

    setCredentials: function (user) {
      this.$session.start();
      this.$session.set("id", user._id);
      this.$session.set("name", user.name);
      this.$session.set("token", user.token);
      this.$session.set("level", "Administrador");
      this.$session.set("v", 1);

      startAuthorization();
      axiosInterceptor();
    },

    login: async function () {
      if (this.validateFields()) {
        let response;
        let error = false;

        await axios
          .post(api + "/panel/admin/login", this.user)
          .then((res) => (response = res))
          .catch((err) => {
            error = true;
            console.log(err);
          });

        if (error) {
          this.$toast.error("Error en la consulta");
        } else {
          if (response.status !== 200) {
            this.$toast.warning("Correo o contraseña incorrecto");
          } else {
            this.setCredentials(response.data);
            this.$router.push("/dashboard");
          }
        }
      }
    },
  },

  mounted: function () {
    const $this = this;

    setTimeout(function () {
      if ($this.$refs.input != undefined) {
        $this.$refs.input.$el.children[0].children[0].focus();
      }
    }, 1000);
  },

  beforeCreate: function () {
    if (this.$session.exists()) {
      this.$router.push("/dashboard");
    }
  },
};
