import Vue from 'vue';
import axios from 'axios';

const $self = new Vue();
let customAxios = axios.create();

export function startAuthorization() {
  if($self.$session && $self.$session.get('token')){
    customAxios.defaults.headers.common['Authorization'] = $self.$session.get('token');
    customAxios.interceptors.request.use(
      config => {
        config.headers['Authorization'] = $self.$session.get('token');
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }
}

export function axiosInterceptor() {
  customAxios.interceptors.response.use(response => {
    return response;
  }, error => {
    if(error.response.status === 401 || error.response.status === 403){
      logout();
    }

    return error;
  });
}

function logout() {
  $self.$session.destroy();
  window.location.reload();
}

export default customAxios;