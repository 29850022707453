export const Utils = {
  methods: {
    toggleLoader: function(status) {
      if(this.$parent._uid == 3){
        this.$parent.loaderVisible = status;
      }else {
        this.$parent.$parent.loaderVisible = status;
      }
    },
  }
}