import customAxios from '@/utils/customAxios.js';
import apiDomain from '@/config/api.js';
import externalComponentBeta from '@/utils/external-component-beta.js';

// Components
const Toolbar = () => externalComponentBeta('toolbar');
const Table = () => externalComponentBeta('table');
const Modal = () => externalComponentBeta('modal');
const Pagination = () => externalComponentBeta('pagination');
import ModalContent from '@/components/Properties/Modal/Modal.vue';

export default {
  name: 'properties',

  components: {
    Toolbar,
    Table,
    Modal,
    ModalContent,
    Pagination
  },

  data() {
    return {
      toolbar: {
        title: 'Listado de Propiedades',
        mainAction: {
          name: 'Agregar Propiedad',
          emit: 'add-item'
        },
        action: {
          name: 'Visibilidad',
          options: [
            {name: 'Todas', value: 'all'},
            {name: 'Habilitadas', value: false},
            {name: 'Deshabilitadas', value: true}
          ]
        },
        order: {
          name: 'Status',
          options: [
            {name: 'Todas', value: 'all'},
            {name: 'En espera', value: 'En Espera'},
            {name: 'Aprobada', value: 'Aprobada'},
            {name: 'Rechazada', value: 'Rechazada'}
          ]
        },
        filter: {
          name: 'Precio',
          options: [
            {name: 'Todas', value: 'all'},
            {name: 'Actualizado', value: true},
            {name: 'Sin actualizar', value: false}
          ]
        },
        search: {
          name: 'Buscar por',
          placeholder: 'Nombre'
        }
      },
      columns: [
        {
          name: 'Nombre'
        },
        {
          name: 'Categoría'
        },
        {
          name: 'Precio Público (x hr)'
        },
        {
          name: 'Capacidad'
        },
        {
          name: 'Status',
          status: true
        },
        {
          name: '',
          status: true
        }
      ],
      rows: [],
      totalItems: 0,
      itemsData: {
        page: 1,
        itemsPerPage: 10,
        search: '',
        filters: {}
      },
      modal: {
        'add': {
          title: 'Agregar Propiedad',
          description: '',
          action: 'Agregar'
        },
        'edit': {
          title: 'Editar Propiedad',
          description: '',
          action: 'Actualizar'
        }
      },
      modalVisible: false,
      modalAction: '',
      statusSelected: 'all',
      filterSelected: 'all',
      visibilitySelected: 'all',
      selectedId: null,
      pagination: {
        currentPage: 1,
        totalPages: 1
      },
    }
  },

  methods: {
    getItems: async function() {
      let items = null;

      if(this.statusSelected != 'all'){
        if(this.statusSelected != 'Aprobada') {
          this.itemsData.filters.status = this.statusSelected;
        }else {
          this.itemsData.filters.status = {$in: [null, 'Aprobada']}
        }
      }else{
        this.itemsData.filters.status = {$in: [null, 'Aprobada', 'En Espera', 'Rechazada']}
      }

      if(this.visibilitySelected != 'all'){
        if(this.visibilitySelected) {
          this.itemsData.filters.disabled = true;
        }else {
          this.itemsData.filters.disabled = {$ne: true}
        }
      }else{
        delete this.itemsData.filters.disabled;
      }

      if(this.filterSelected != 'all'){
        if(this.filterSelected) {
          this.itemsData.filters.publicPriceUpdated = true;
        }else {
          this.itemsData.filters.publicPriceUpdated = {$ne: true}
        }
      }else{
        delete this.itemsData.filters.publicPriceUpdated;
      }

      await customAxios
        .post(apiDomain + '/panel/property/filter', this.itemsData)
        .then(resp => items = resp)
        .catch(err => items = false);

      if(!items) {
        this.$toast.error('Error en la consulta');
      }else if(items.status == 204) {
        this.rows = [];
        this.totalItems = 0;
        this.pagination.totalPages = 1;
      }else {
        this.rows = items.data.items;
        this.pagination.totalPages = items.data.totalPages;
        this.totalItems = items.data.totalItems;
      }
    },

    changePage: function(val){
      this.itemsData.page = val;
      this.getItems();
    },

    filterChanged: function(){
      this.itemsData.page = 1;
      this.getItems();
    },

    searchToolbar: function(val) {
      this.itemsData.search = val;
      this.filterChanged();
    },

    orderToolbar: function(val) {
      this.statusSelected = val;
      this.filterChanged();
    },

    filterToolbar: function(val) {
      this.filterSelected = val;
      this.filterChanged();
    },

    actionToolbar: function(val) {
      this.visibilitySelected = val;
      this.filterChanged();
    },

    addItem: function(){
      this.modalAction = 'add';
      this.selectedId = null;
      this.modalVisible = true;
    },

    editItem: function(id) {
      this.modalAction = 'edit';
      this.selectedId = id;
      this.modalVisible = true;
    },

    singleDelete: function(id) {
      this.deleteConfirmation([id]);
    },

    deleteConfirmation: function(ids){
      let msg = "¿Estás seguro de eliminar ";
      msg += (ids.length > 1)?"estos elementos?":"este elemento?";

      this.$confirm({
        message: msg,
        button: {
          no: 'No',
          yes: 'Sí'
        },
        callback: confirm => {
          if(confirm) {
            this.deleteItems(ids);
          }
        }
      })
    },

    deleteItems: async function(ids){
      let response = null;

      this.toggleLoader(true);

      await customAxios
        .post(apiDomain + '/panel/property/delete', {items: ids})
        .then(resp => response = resp)
        .catch(err => response = false);

      this.toggleLoader(false);

      if(!response) {
        this.$toast.error('Error eliminando item');
      }else {
        if(response.status != 200){
          this.$toast.error('Error eliminando item');
        }else{
          this.$toast.success('Item/s eliminado/s con éxito');
          this.getItems();
        }
      }
    },

    toggleModal: function(status){
      this.modalVisible = status;

      if(!status){
        this.getItems();

        if(this.modalAction == 'edit'){
          this.$router.push({ path: '/propiedades'});
        }
      }
    },

    verifyUrlParams: function() {
      const id = this.$route.params.id || null;

      if(id) {
        this.selectedId = id;
        this.modalAction = 'edit';
        this.modalVisible = true;
      }
    },

    changeToApproved: async function(id) {
      let response;

      await customAxios
        .put(apiDomain+'/panel/property/status/approved/'+id)
        .then(resp => response = resp)
        .catch(err => console.log(err));

      return response;
    },

    changeToRejected: async function(id) {
      let response;

      await customAxios
        .put(apiDomain+'/panel/property/status/rejected/'+id)
        .then(resp => response = resp)
        .catch(err => console.log(err));

      return response;
    },

    updateStatus: async function(id, value) {
      let response;

      switch (value) {
        case 'approved':
          response = await this.changeToApproved(id);
          break;
        case 'rejected':
          response = await this.changeToRejected(id);
          break;
      }

      if (typeof response !== 'undefined' && response.status == 200) {
        this.getItems();
        this.$toast.success('Propiedad actualizada con éxito');
      } else {
        this.$toast.error('Error al actualizar la propiedad');
      }
    }
  },

  computed: {
    currentPage() {
      return this.pagination.currentPage;
    }
  },

  watch: {
    currentPage(val) {
      this.changePage(val);
    },

    $route() {
      this.verifyUrlParams();
    }
  },

  mounted(){
    this.verifyUrlParams();
    this.getItems();
  }
}
