import customAxios from '@/utils/customAxios.js';
import apiDomain from '@/config/api.js';
import externalComponentBeta from '@/utils/external-component-beta.js';

// Components
const FormInput = () => externalComponentBeta('formInput');
const IconPicker = () => externalComponentBeta('iconPicker');

export default {
  data(){
    return{
      item: {
        title: '',
        icon: ''
      }
    }
  },

  props: {
    id: String,
    action: String
  },

  components: {
    FormInput,
    IconPicker
  },

  methods: {
    getItem: async function() {
      let item = null;
      const $this = this;

      this.$parent.toggleLoader(true);

      await customAxios
        .get(apiDomain+'/panel/exterior/'+ this.id)
        .then(resp => item = resp)
        .catch(err => item = false);

      setTimeout(function(){
        $this.$parent.toggleLoader(false);
      }, 800);

      if(!item) {
        this.$toast.error('Error en la consulta');
      }else {
        if(item.status == 204){
          this.$toast.error('Error en la consulta');
        }else{
          this.item = item.data;
        }
      }
    },

    saveItem: async function() {
      let item = null;

      this.$parent.toggleLoader(true);

      if(this.action == 'edit'){
        await customAxios
          .put(apiDomain+'/panel/exterior/'+ this.id, this.item)
          .then(resp => item = resp)
          .catch(err => item = false);
      }else{
        await customAxios
          .post(apiDomain+'/panel/exterior/', this.item)
          .then(resp => item = resp)
          .catch(err => item = false);
      }

      this.$parent.toggleLoader(false);

      if(!item) {
        this.$toast.error('Error en la consulta');
      }else {
        if(item.status == 204){
          this.$toast.error('Error en la consulta');
        }else{
          this.$toast.success('Guardado con éxito');
          this.$emit('toggle-modal', false);
        }
      }
    },

    saveChanges: function() {
      if(this.validateData()){
        this.saveItem();
      }
    },

    validateData: function() {
      let success = true;

      if(this.item.title == ''){
        success = false;
        this.$toast.error('Título inválido.');
      }else if(this.item.icon == '') {
        success = false;
        this.$toast.error('Ícono inválido.');
      }

      return success;
    }
  },

  mounted() {
    if(this.action == 'edit'){
      this.getItem();
    }
  }
}
